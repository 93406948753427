import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Getting Started",
  "type": "Page"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Primabuild UI is the Primabuild's Design System React Component Library. It is based on
`}<a parentName="p" {...{
        "href": "https://dashboard-ui.com"
      }}>{`Dashboard UI`}</a>{` and is distributed as a npm package.`}</p>
    <h2>{`Installing the package`}</h2>
    <p>{`The package is published on a public npm registry as a private npm package.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ npm install @primabuild/ui
`}</code></pre>
    <h2>{`Add fonts`}</h2>
    <p>{`You need to add `}<a parentName="p" {...{
        "href": "https://fonts.google.com/specimen/Source+Sans+Pro"
      }}>{`Source Sans Pro`}</a>{` to
your project.`}</p>
    <h2>{`Add theme provider`}</h2>
    <p>{`In your application you need to add Theme Provider component and specify a theme.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { ThemeProvider, Theme, Card, Heading } from "@primabuild/ui";
import "@primabuild/ui/index.css";

const App = () => (
    <ThemeProvider theme={Theme.LIGHT}>
      <Card>
        <Heading level={1}>Hello World</Heading>
      </Card>
    </ThemeProvider>
);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      